import { useLocation } from "react-router-dom";
import "./ThankYou.scss";
import ThankYou from "../../Assets/Images/thankYou.jpg";
const SucessPage = () => {
  const location = useLocation();
  const enquiryData = location.state;

  return (
    <section>
      <div className="sucess-container">
        <img src={ThankYou} alt="thankYou.jpeg" className="thank-you-image" />
        <h1>Your enquiry has been saved with us.</h1>
        <h1>Your enquiry number is : {enquiryData?.data?.enquiryId}.</h1>
      </div>
    </section>
  );
};

export default SucessPage;
