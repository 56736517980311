import React from "react";
import pagenotfoundImg from "../../Assets/Images/Search.svg";
import "./NotFound.scss";

const NotFoundPage = () => {
  return (
    <div className="not-found-page-container">
      <img
        src={pagenotfoundImg}
        alt="notfoundpage"
        className="not-found-image"
      />
      <h2 className="not-found-heading">
        THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST
      </h2>
      <h3 className="not-found-subheading">
        YOU MAY HAVE MISTYPED THE ADDRESS OR THE PAGE MAY HAVE MOVED
      </h3>
    </div>
  );
};

export default NotFoundPage;
