import React, { useState } from "react";
import { DatePicker } from "antd";

import "./DatePicker.css";
import moment from "moment";

const Datepicker = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, required } = props;

  let format = props.format ? props.format : "DD-MM-YYYY";

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;
  // const disabledDate = (current) => {
  //   return current && current >= moment().add("day");
  // };
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <DatePicker
        onChange={props.onChange}
        defaultValue={value}
        placeholder=""
        // disabled={disabledDate}
        format={format}
        {...props}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder}
        {requiredMark}
      </label>
    </div>
  );
};

export default Datepicker;
