import { useEffect, useState } from "react";
import "./SchoolAdmissionDetails.scss";
import axios from "axios";
import NotFoundPage from "../NoDataFound/NotFound";
import { Button, Form, Spin, Select, notification } from "antd";
import FloatInputAll from "../Common/FloatLabel/InputAll";
import InputFloatLabel from "../../Components/Common/FloatLabel/Input";
import FloatSelect from "../Common/FloatLabel/Select";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const SchoolAdmissionForm = () => {
  const [schoolData, setSchoolData] = useState([]);
  const [loading, setLoading] = useState(true);
  const instituteData = sessionStorage.getItem("userData");
  const [instituteInfo, setInstituteInfo] = useState(
    JSON.parse(instituteData) || []
  );
  const navigate = useNavigate();
  const [admissionDetailsForm] = Form.useForm();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uniqueId = urlParams.get("id");

    if (uniqueId) {
      setLoading(true);
      axios
        .get(
          `https://api.edpedia.co/v1.0/admissions/api/enquiry-data/${uniqueId}`
        )
        .then((response) => {
          setLoading(false);
          setInstituteInfo(response.data.data);
          setSchoolData(response.data.data);
          sessionStorage.setItem(
            "instituteData",
            JSON.stringify(response.data.data)
          );
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const IndianSchoolClasses = [
    "Nursery",
    "L.K.G",
    "U.K.G",
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
  ];
  const today = new Date();
  const day = today.getDate();
  const month = today
    .toLocaleString("default", { month: "long" })
    .toUpperCase();
  const year = today.getFullYear();
  const formattedDate = `${day} ${month}, ${year}`;

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: "",
      description: "Please fill all the required fields",
      duration: 5,
      placement: "topRight",
    });
  };

  const onFinish = (values) => {
    const siblingDetails = {
      siblingName: values.siblingName,
      siblingRollNumber: values.siblingRollNumber,
      siblingClass: values.siblingClass,
      siblingBranch: values.siblingBranch,
    };
    const payLoad = {
      ...values,
      enrolledBranch: instituteInfo?.branchCode,
      caAddress: "",
      caCountry: "",
      caState: "",
      caCity: "",
      caZipcode: "",
      landlineNumber: "",
      paAddress: "",
      ecName: "",
      ecRelationship: "",
      ecMobileNumber: "",
      sourceOfInfo: "",
      affiliation: true,
      schoolLunch: true,
      sourceOtherInfo: "",
      allergiesOtherInfo: "",
      healthConditionInfo: "",
      enquiryDate: "",
      status: "",
      siblings: [],
      pocRelationShip: "",
      pocEmailId: "",
      alumnusOfMother: true,
      pocName: "",
      pointOfContact: "",
      pocMobileNumber: "",
      motherEmailId: "",
      alumnusOfFather: true,
      motherName: "",
      motherOccupation: "",
      motherQualification: "",
      motherOfficeAddress: "",
      motherOrganisation: "",
      motherMobileNumber: "",
      fatherEmailId: "",
      dateOfBirth: "",
      gender: "",
      motherTongue: "",
      aadhaarNumber: "",
      nationality: "",
      religion: "",
      passportNumber: "",
      caste: "",
      bloodGroup: "",
      applyEarlier: true,
      attendedYear: "",
      attendedClass: "",
      attendedBoard: "",
      reasonForLeaving: "",
      identificationMark1: "",
      identificationMark2: "",
      physicalSupport: "",
      learningSupport: "",
      healthCondition: "",
      allergies: "",
      parentAadhaarNumber: "",
      nameAsPerAadhaar: "",
      fatherQualification: "",
      fatherOfficeAddress: "",
      fatherOrganisation: "",
      fatherMobileNumber: "",
      email: "",
      admissionType: "",
      academicYear: "",
      board: "",
      middleName: "",
      lastName: "",
    };
    axios
      .post(
        "https://api.edpedia.co/v1.0/admissions/api/enquiry/create",
        payLoad
      )
      .then((response) => {
        admissionDetailsForm.resetFields();
        notification.success({
          message: `Enquiry created successfully`,
        });
        navigate("/sucess-page", { state: response.data });
      })
      .catch((error) => {
        notification.error({
          message: "An Error Occured",
          description: "Enquiry creating failed, try again later.",
        });
      });
  };

  return (
    <main>
      <div>
        <div className="main_bg">
          <div className="header-container">
            <p className="today-date">{formattedDate}</p>
            <div className="header">
              <div>
                <img
                  // src="https://png.pngtree.com/png-clipart/20230407/original/pngtree-education-and-school-logo-design-template-png-image_9035365.png"
                  src={instituteInfo.instLogo}
                  alt="InstituteLogo"
                  className="institute-logo"
                />
              </div>
              <div className="content-container">
                <h1>{instituteInfo?.branchName || "School Name"}</h1>
                <h4>{instituteInfo?.address || "School Address"}</h4>

                <h2 className="heading">
                  Online Enquiry Form for little heroes
                </h2>
              </div>
            </div>
          </div>
          <Form
            onFinishFailed={onFinishFailed}
            form={admissionDetailsForm}
            onFinish={onFinish}
          >
            <div className="container">
              <div className="title_container">
                <h5>Admission Details</h5>
              </div>
              <div className="feild_container grid-container"></div>
            </div>
            <div className="feild_container grid-container">
              <Form.Item
                name="firstName"
                className="input-field"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label={<span>Student Name (*)</span>} />
              </Form.Item>
              <Form.Item
                name="FatherName"
                className="input-field"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label={<span>Father Name (*)</span>} />
              </Form.Item>
              <Form.Item name="FatherOccupation" className="input-field">
                <InputFloatLabel label={<span>Father Occupation </span>} />
              </Form.Item>

              <Form.Item
                name="enrolledClass"
                rules={[{ required: true, message: "" }]}
                className="input-field"
              >
                <FloatSelect
                  label={<span>Class (*) </span>}
                  style={{
                    textAlign: "Left",
                    borderColor: "grey",
                    borderSize: "solid",
                    // height: 50,
                  }}
                  className="select-dropdown"
                >
                  {IndianSchoolClasses?.map((className) => (
                    <Option key={className} value={className}>
                      {className}
                    </Option>
                  ))}
                </FloatSelect>
              </Form.Item>
              <Form.Item name="attendedSchoolName" className="input-field ">
                <FloatInputAll label={<span>Previous School Name </span>} />
              </Form.Item>
              <Form.Item
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    min: 10,
                    message: "Please enter valid phone number",
                  },
                  {
                    max: 10,
                    message: "Cannot exceed more than 10",
                  },
                ]}
                className="input-field "
              >
                <InputFloatLabel
                  label={<span> Contact Number 1 (*)</span>}
                  type="number"
                />
              </Form.Item>
              <Form.Item
                name="parentWhatsappNumber"
                rules={[
                  {
                    // required: true,
                    message: "",
                  },
                  {
                    min: 10,
                    message: "Please enter valid phone number",
                  },
                  {
                    max: 10,
                    message: "Cannot exceed more than 10",
                  },
                ]}
                className="input-field "
              >
                <InputFloatLabel
                  label={<span> Contact Number 2 </span>}
                  type="number"
                />
              </Form.Item>
            </div>
            <Button className="form-submit" htmlType="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </main>
  );
};
export default SchoolAdmissionForm;
