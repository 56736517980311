import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AdmissionDetails from "./Components/AdmissionDetails/AdmissionDetails";
import SucessPage from "./Components/ThankYou/ThankYou";
import SchoolAdmissionForm from "./Components/AdmissionDetails/SchoolAdmissionDetails";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Navigate to="/enquiry" replace />} />
          <Route path="/enquiry" element={<AdmissionDetails />} />
          {/* <Route path="/enquiry" element={<SchoolAdmissionForm />} /> */}
          <Route path="/sucess-page" element={<SucessPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
